import { initialState } from '.';
import { FloorPlanAction, FloorPlanState } from 'types/floorPlan';

export function floorPlanReducer(
  state: FloorPlanState = initialState.floorPlan,
  action: FloorPlanAction,
): FloorPlanState {
  switch (action.type) {
    case 'FloorPlan.setIsLoading':
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case 'FloorPlan.setData':
      return {
        ...state,
        restaurantId: action.payload.restaurantId,
        rooms: action.payload.rooms,
        tables: action.payload.tables,
        walls: action.payload.walls,
        texts: action.payload.texts,
      };
    case 'FloorPlan.clearData':
      return {
        ...state,
        restaurantId: initialState.floorPlan.restaurantId,
        rooms: initialState.floorPlan.rooms,
        tables: initialState.floorPlan.tables,
        walls: initialState.floorPlan.walls,
        texts: initialState.floorPlan.texts,
      };
    default:
      return state;
  }
}
