import { Origin, Statuses } from 'types/reservation';
import { BookingFormData } from 'types/app/booking';

export const bookingInitialState: BookingFormData = {
  reservation_date: null,
  status: Statuses.Confirmed,
  start_time: 0,
  end_time: 0,
  guests: 0,
  tables: [],
  important_message: null,
  lock_table: false,
  comments: [
    {
      text: '',
    },
  ],
  guest_comments: [],
  origin: Origin.Phone,
  tags: [],
  waiter_id: null,
  special_offer: null,
  files: [],
  created_at: 0,
  updated_at: 0,
  original_start_time: 0,
  notifications: {
    language: 'en',
    sms: false,
    email: false,
    resend: false,
    table_ready: false,
  },
  client: {
    name: '',
    email: '',
    phone: '',
    company: '',
    subscribed: false,
    vip: false,
    big_spender: false,
    blacklisted: false,
    tags: [],
  },
  payments: null,
  stripe_payment_data: null,
  late_cancellation: null,
  widget_fields: [],
};
