import moment from 'moment';
import { AppState } from 'types';
import { Dispatch } from 'redux';
import {
  addCommentCall,
  deleteCommentCall,
  editCommentCall,
  getAverageRatingCall,
  getGuestDetailsCall,
  getPaymentsCall,
  getSpecialOffersCall,
  setBigSpenderCall,
  setVipCall,
} from 'api/reservation';
import calendarActions from './calendar';
import {
  AverageRating,
  AverageRatingLoadedAction,
  CommentAddedAction,
  CommentDeletedAction,
  CommentEditedAction,
  GuestDetailsLoadedAction,
  LateCancellationNoShow,
  LateCancellationNoShowAction,
  Notifications,
  Origin,
  Payments,
  PaymentsLoadedAction,
  ReservationState,
  ReservationValidationErrorAddedAction,
  ReservationValidationErrorRemovedAction,
  SearchedUpdatedAction,
  SpecialOffersLoadedAction,
  SpecialOfferUpdatedAction,
  Statuses,
} from 'types/reservation';
import { GuestDetails, Reply, ValidationError, Waiter } from 'types/calendar';
import notificationsActions from './notifications';

const reservationActions = {
  updateReservationToNewDefaults(partialReservation: Partial<ReservationState>) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      const restaurant = getState().calendar.restaurant;
      const reservation = {
        id: undefined,
        tables: [],
        startTime: moment(),
        endTime: undefined,
        numberOfGuests: 0,
        name: '',
        email: '',
        phoneNumber: '',
        company: '',
        status: Statuses.Confirmed,
        notifications: {
          sms:
            (restaurant.settings &&
              restaurant.settings.sms_notify_status &&
              restaurant.settings.sms_notify_default_on) ||
            false,
          email: true,
          resend: false,
          tableReady: false,
          language: (restaurant.settings && restaurant.settings.default_language) || '',
        },
        origin: Origin.Phone,
        clientIsVip: false,
        clientIsBigSpender: false,
        showBigSpenderTag: false,
        waiter:
          restaurant.waiters && restaurant.waiters.length === 1
            ? restaurant.waiters[0]
            : { tid: '_none' },
        originalWaiter:
          restaurant.waiters && restaurant.waiters.length === 1
            ? restaurant.waiters[0]
            : { tid: '_none' },
        comments: [],
        newComment: '',
        showInitialTimeBadge: false,
        autoArrivalDisabled: false,
        specialOffer: { nid: '_none' },
        specialOffers: [],
        tags: [],
        clientDetails: '',
        clientTags: [],
        timeFormat: '',
        averageRating: undefined,
        guestDetails: {
          arrivedCount: 0,
          lastVisit: '',
          cancelledCount: 0,
          noShowCount: 0,
        },
        reservationSavingErrors: [],
        payments: {
          currencies: {},
          payments: [],
        },
        dinningRooms: [],
        lateCancellationNoShow: {
          enabled: 0,
          time: 0,
          maxAmount: 0,
          currency: '',
          chargeMode: '',
          paymentData: {
            id: '',
            object: '',
            cancellation_reason: null,
            client_secret: '',
            created: 0,
            description: null,
            last_setup_error: '',
            livemode: false,
            next_action: null,
            payment_method: '',
            payment_method_types: [],
            status: '',
            usage: '',
            paymentSuccess: false,
            paidAmount: 0,
            paidAt: '',
          },
        },
      } as ReservationState;
      dispatch({
        type: 'Reservation.ReservationUpdated',
        payload: { reservation: { ...reservation, ...partialReservation } },
      });
    };
  },
  updateReservation(reservation: ReservationState) {
    return {
      type: 'Reservation.ReservationUpdated',
      payload: { reservation: { ...reservation } },
    };
  },
  updateNumberOfGuests(numberOfGuests: number) {
    return {
      type: 'Reservation.NumberOfGuestsUpdated',
      payload: { numberOfGuests: numberOfGuests },
    };
  },
  updateTables(tables: string[]) {
    return {
      type: 'Reservation.TablesUpdated',
      payload: { tables: tables },
    };
  },
  updateGuestDetails(guestDetails: GuestDetails) {
    return {
      type: 'Reservation.GuestDetailsLoaded',
      payload: { guestDetails },
    } as GuestDetailsLoadedAction;
  },
  updateAverageRating(averageRating?: AverageRating) {
    return {
      type: 'Reservation.AverageRatingLoaded',
      payload: { averageRating },
    } as AverageRatingLoadedAction;
  },
  updatePayments(payments?: Payments) {
    return {
      type: 'Reservation.PaymentsLoaded',
      payload: { payments },
    } as PaymentsLoadedAction;
  },
  loadSpecialOffers(startTime: moment.Moment) {
    return (dispatch: Dispatch) => {
      getSpecialOffersCall(startTime).then((specialOffers) => {
        if (specialOffers['_none'] !== undefined) {
          specialOffers['_none'] = 'Please select';
        }
        dispatch({
          type: 'Reservation.SpecialOffersLoaded',
          payload: { specialOffers },
        } as SpecialOffersLoadedAction);
      });
    };
  },
  loadAverageRating(clientId: number) {
    return (dispatch: Dispatch) => {
      getAverageRatingCall(clientId).then((averageRating) => {
        dispatch({
          type: 'Reservation.AverageRatingLoaded',
          payload: { averageRating },
        } as AverageRatingLoadedAction);
      });
    };
  },
  loadGuestDetails(clientId: number) {
    return (dispatch: Dispatch) => {
      getGuestDetailsCall(clientId).then((guestDetails) => {
        dispatch({
          type: 'Reservation.GuestDetailsLoaded',
          payload: { guestDetails: guestDetails.clientData },
        } as GuestDetailsLoadedAction);
      });
    };
  },
  loadPayments(reservationId: number) {
    return (dispatch: Dispatch) => {
      getPaymentsCall(reservationId).then((payments) => {
        dispatch({
          type: 'Reservation.PaymentsLoaded',
          payload: { payments },
        } as PaymentsLoadedAction);
      });
    };
  },
  updateStartTime(startTime: moment.Moment) {
    return (dispatch: Dispatch) => {
      dispatch({
        type: 'Reservation.StartTimeUpdated',
        payload: { startTime: startTime },
      });
      dispatch<any>(reservationActions.loadSpecialOffers(startTime));
    };
  },
  updateEndTime(endTime?: moment.Moment) {
    return {
      type: 'Reservation.EndTimeUpdated',
      payload: { endTime: endTime },
    };
  },
  updateId(id?: number) {
    return {
      type: 'Reservation.IdUpdated',
      payload: { id: id },
    };
  },
  updateName(name: string) {
    return {
      type: 'Reservation.NameUpdated',
      payload: { name: name },
    };
  },
  updateEmail(email: string) {
    return {
      type: 'Reservation.EmailUpdated',
      payload: { email: email },
    };
  },
  updatePhoneNumber(phoneNumber: string) {
    return {
      type: 'Reservation.PhoneNumberUpdated',
      payload: { phoneNumber: phoneNumber },
    };
  },
  updateCompany(company: string) {
    return {
      type: 'Reservation.CompanyUpdated',
      payload: { company: company },
    };
  },
  updateStatus(status: Statuses) {
    return {
      type: 'Reservation.StatusUpdated',
      payload: { status: status },
    };
  },
  updateWaiter(waiter: Waiter) {
    return {
      type: 'Reservation.WaiterUpdated',
      payload: { waiter: waiter },
    };
  },
  updateNewComment(comment: string) {
    return {
      type: 'Reservation.NewCommentUpdated',
      payload: { newComment: comment },
    };
  },
  updateSpecialOffer(specialOfferNid: string) {
    return {
      type: 'Reservation.SpecialOfferUpdated',
      payload: { specialOfferNid: specialOfferNid },
    } as SpecialOfferUpdatedAction;
  },
  updateTags(tags: string[]) {
    return {
      type: 'Reservation.TagsUpdated',
      payload: { tags: tags },
    };
  },
  updateClientDetails(clientDetails: string) {
    return {
      type: 'Reservation.ClientDetailsUpdated',
      payload: { clientDetails: clientDetails },
    };
  },
  updateClientTags(clientTags: string[]) {
    return {
      type: 'Reservation.ClientTagsUpdated',
      payload: { clientTags: clientTags },
    };
  },
  updateNotifications(notifications: Partial<Notifications>) {
    return {
      type: 'Reservation.NotificationsUpdated',
      payload: { notifications: notifications },
    };
  },
  deleteComment(commentId: number) {
    return (dispatch: Dispatch) => {
      deleteCommentCall(commentId)
        .then(() => {
          dispatch(
            notificationsActions.enqueueSnackbar({
              message: 'Successful!',
              options: { variant: 'success' },
            }),
          );
          dispatch({
            type: 'Reservation.CommentDeleted',
            payload: { commentId: commentId },
          } as CommentDeletedAction);
          dispatch<any>(calendarActions.getReservations());
          dispatch<any>(calendarActions.searchReservations());
        })
        .catch(() => {
          dispatch(
            notificationsActions.enqueueSnackbar({
              message: 'Something went wrong. Please try again later.',
              options: { variant: 'warning' },
            }),
          );
        });
    };
  },
  editComment(commentId: number, commentBody: string) {
    return (dispatch: Dispatch) => {
      return editCommentCall(commentId, commentBody)
        .then(() => {
          dispatch(
            notificationsActions.enqueueSnackbar({
              message: 'Successful!',
              options: { variant: 'success' },
            }),
          );
          dispatch({
            type: 'Reservation.CommentEdited',
            payload: { commentId: commentId, commentBody: commentBody },
          } as CommentEditedAction);
          dispatch<any>(calendarActions.getReservations());
          dispatch<any>(calendarActions.searchReservations());
        })
        .catch(() => {
          dispatch(
            notificationsActions.enqueueSnackbar({
              message: 'Something went wrong. Please try again later.',
              options: { variant: 'warning' },
            }),
          );
        });
    };
  },
  addComment(commentBody: string, reservationId: number) {
    return (dispatch: Dispatch) => {
      return addCommentCall(commentBody, reservationId)
        .then((response: Reply) => {
          dispatch(
            notificationsActions.enqueueSnackbar({
              message: 'Successful!',
              options: { variant: 'success' },
            }),
          );
          dispatch({
            type: 'Reservation.CommentAdded',
            payload: { comment: response },
          } as CommentAddedAction);
          dispatch<any>(calendarActions.getReservations());
          dispatch<any>(calendarActions.searchReservations());
        })
        .catch(() => {
          dispatch(
            notificationsActions.enqueueSnackbar({
              message: 'Something went wrong. Please try again later.',
              options: { variant: 'warning' },
            }),
          );
        });
    };
  },
  setVip(isVip: boolean) {
    return {
      type: 'Reservation.ClientVipSet',
      payload: { isVip: isVip },
    };
  },
  sendVip(isVip: boolean, clientId: number) {
    return (dispatch: Dispatch) => {
      return setVipCall(isVip, clientId)
        .then(() => {
          dispatch<any>(calendarActions.getReservations());
          dispatch<any>(calendarActions.searchReservations());
        })
        .catch(() => {
          dispatch(
            notificationsActions.enqueueSnackbar({
              message: 'Something went wrong. Please try again later.',
              options: { variant: 'warning' },
            }),
          );
          return Promise.reject('error');
        });
    };
  },
  setBigSpender(isBigSpender: boolean) {
    return {
      type: 'Reservation.ClientBigSpenderSet',
      payload: { isBigSpender },
    };
  },
  sendBigSpender(isBigSpender: boolean, clientId: number) {
    return (dispatch: Dispatch) => {
      return setBigSpenderCall(isBigSpender, clientId)
        .then(() => {
          dispatch<any>(calendarActions.getReservations());
          dispatch<any>(calendarActions.searchReservations());
        })
        .catch(() => {
          dispatch(
            notificationsActions.enqueueSnackbar({
              message: 'Something went wrong. Please try again later.',
              options: { variant: 'error' },
            }),
          );

          return Promise.reject();
        });
    };
  },
  updateSearched(searched: boolean) {
    return {
      type: 'Reservation.SearchedUpdated',
      payload: { searched },
    } as SearchedUpdatedAction;
  },
  addReservationValidationError(errors: ValidationError[]) {
    return {
      type: 'Reservation.ReservationValidationErrorAdded',
      payload: { errors: errors },
    } as ReservationValidationErrorAddedAction;
  },
  removeReservationValidationError() {
    return {
      type: 'Reservation.ReservationValidationErrorRemoved',
    } as ReservationValidationErrorRemovedAction;
  },
  updateLateCancellationNoShowData(lateCancellationNoShow: LateCancellationNoShow) {
    return {
      type: 'Reservation.LateCancellationNoShowDataLoaded',
      payload: { lateCancellationNoShow },
    } as LateCancellationNoShowAction;
  },
};

export default reservationActions;
