import { ApplicationAction, ApplicationState } from 'types/application';
import { initialState } from './index';

export function applicationReducer(
  state: ApplicationState = initialState.application,
  action: ApplicationAction,
): ApplicationState {
  switch (action.type) {
    case 'Application.LanguagesLoaded':
      return {
        ...state,
        languages: action.payload.languages,
      };
    case 'Application.RestaurantsListLoaded':
      return {
        ...state,
        restaurantsList: action.payload.restaurantsList,
      };
    case 'Application.PrintingUpdate':
      return {
        ...state,
        printing: action.payload.printing,
      };
    case 'Application.SetCurrentTime':
      return {
        ...state,
        currentTime: action.payload.time,
      };
    case 'Application.SetConnectionStatus':
      return {
        ...state,
        connectionStatus: {
          ...state.connectionStatus,
          ...action.payload.connectionStatus,
        },
      };
    default:
      return state;
  }
}
