import SystemState, { SystemStatusAction } from 'types/system';
import { initialState } from './index';

export function systemReducer(
  state: SystemState = initialState.system,
  action: SystemStatusAction,
): SystemState {
  switch (action.type) {
    case 'System.StatusLoaded':
      return {
        ...state,
        status: action.payload.status,
      };
    default:
      return state;
  }
}
