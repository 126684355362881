import {
  DiningRoomsFilterUpdatedAction,
  FiltersOptions,
  FiltersResetAction,
  OptionsFilterUpdatedAction,
  SelectedHours,
  SelectedHoursUpdatedAction,
  SelectedStatusesUpdatedAction,
  SortDirectionUpdatedAction,
  SortOptionUpdatedAction,
  TagFilterUpdatedAction,
} from 'types/filters';
import { Statuses } from 'types/reservation';

const filtersActions = {
  updateSelectedHours(selectedHours: SelectedHours) {
    return {
      type: 'Filters.SelectedHoursUpdated',
      payload: { selectedHours },
    } as SelectedHoursUpdatedAction;
  },
  updateSelectedStatuses(selectedStatuses: Statuses[]) {
    return {
      type: 'Filters.SelectedStatusesUpdated',
      payload: { selectedStatuses },
    } as SelectedStatusesUpdatedAction;
  },
  updateDiningRoomsFilter(dinningRooms: number[]) {
    return {
      type: 'Filters.DiningRoomsFilterUpdated',
      payload: { dinningRooms },
    } as DiningRoomsFilterUpdatedAction;
  },
  updateOptionsFilter(options: FiltersOptions[]) {
    return {
      type: 'Filters.OptionsFilterUpdated',
      payload: { options },
    } as OptionsFilterUpdatedAction;
  },
  updateTagFilter(tag: string | null) {
    return {
      type: 'Filters.TagFilterUpdated',
      payload: { tag },
    } as TagFilterUpdatedAction;
  },
  updateSortOption(value: string) {
    return {
      type: 'Filters.SortOptionUpdated',
      payload: { value },
    } as SortOptionUpdatedAction;
  },
  updateSortDirection(value: string) {
    return {
      type: 'Filters.SortDirectionUpdated',
      payload: { value },
    } as SortDirectionUpdatedAction;
  },
  resetFilters() {
    return {
      type: 'Filters.FiltersReset',
    } as FiltersResetAction;
  },
};

export default filtersActions;
