import { initialState } from '.';
import { NotesAction, NotesState } from 'types/notes';

export function notesReducer(
  state: NotesState = initialState.notes,
  action: NotesAction,
): NotesState {
  switch (action.type) {
    case 'Notes.setIsDrawerOpen':
      return {
        ...state,
        isDrawerOpen: action.payload.isOpen,
      };
    case 'Notes.setCreateNoteLoading':
      return {
        ...state,
        createNoteLoading: action.payload.isLoading,
      };
    case 'Notes.setDeleteNoteLoading':
      return {
        ...state,
        deleteNoteLoading: action.payload.isLoading,
      };
    case 'Notes.setGetNotesLoading':
      return {
        ...state,
        getNotesLoading: action.payload.isLoading,
      };
    case 'Notes.setNotes':
      return {
        ...state,
        notes: action.payload.notes,
      };
    case 'Notes.setSelectedNote':
      return {
        ...state,
        selectedNote: action.payload.note,
      };
    default:
      return state;
  }
}
