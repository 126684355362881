import { initialState } from './index';
import { FiltersAction, FiltersState } from 'types/filters';

export function filtersReducer(
  state: FiltersState = initialState.filters,
  action: FiltersAction,
): FiltersState {
  switch (action.type) {
    case 'Filters.SelectedHoursUpdated':
      return {
        ...state,
        selectedHours: action.payload.selectedHours,
      };
    case 'Filters.SelectedStatusesUpdated':
      return {
        ...state,
        selectedStatuses: action.payload.selectedStatuses,
      };
    case 'Filters.DiningRoomsFilterUpdated':
      return {
        ...state,
        dinningRooms: action.payload.dinningRooms,
      };
    case 'Filters.OptionsFilterUpdated':
      return {
        ...state,
        options: action.payload.options,
      };
    case 'Filters.TagFilterUpdated':
      return {
        ...state,
        tag: action.payload.tag,
      };
    case 'Filters.SortOptionUpdated':
      return {
        ...state,
        sort: {
          ...state.sort,
          option: action.payload.value,
        },
      };
    case 'Filters.SortDirectionUpdated':
      return {
        ...state,
        sort: {
          ...state.sort,
          direction: action.payload.value,
        },
      };
    case 'Filters.FiltersReset':
      return {
        ...initialState.filters,
      };
    default:
      return state;
  }
}
