import { AppDispatch } from 'redux/store';
import { AppState } from 'types';
import { IPreferenceKey, PreferencesActionTypes } from 'types/app/preferences';

const preferencesActions = {
  setDefaultPreferences() {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
      const { auto_arrival, walk_in, canceled, no_show, original_time, slot_guests } =
        getState().app.settings.reservation;

      const preferences = getState().app.preferences;

      const payload = {
        auto_arrival,
        walk_in,
        canceled,
        no_show,
        original_time,
        slot_guests,
        waiting_list: false,
      };
      const isPreferencesEmpty = Object.keys(preferences).length === 0;

      if (isPreferencesEmpty) {
        dispatch({
          type: PreferencesActionTypes.SetDefaultPreferences,
          payload,
        });
      }
    };
  },
  updatePreference: (key: IPreferenceKey, value: boolean | string) => {
    return {
      type: PreferencesActionTypes.UpdatePreference,
      payload: { key, value },
    };
  },
};

export default preferencesActions;
