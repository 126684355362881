import { ReservationAction, ReservationState } from 'types/reservation';
import { initialState } from './index';

export function reservationReducer(
  state: ReservationState = initialState.reservation,
  action: ReservationAction,
): ReservationState {
  switch (action.type) {
    case 'Reservation.ReservationUpdated':
      return {
        ...state,
        ...action.payload.reservation,
      };
    case 'Reservation.NumberOfGuestsUpdated':
      return {
        ...state,
        numberOfGuests: action.payload.numberOfGuests,
      };
    case 'Reservation.TablesUpdated':
      return {
        ...state,
        tables: action.payload.tables,
      };
    case 'Reservation.StartTimeUpdated':
      return {
        ...state,
        startTime: action.payload.startTime,
      };
    case 'Reservation.EndTimeUpdated':
      return {
        ...state,
        endTime: action.payload.endTime,
      };
    case 'Reservation.IdUpdated':
      return {
        ...state,
        id: action.payload.id,
      };
    case 'Reservation.NameUpdated':
      return {
        ...state,
        name: action.payload.name,
      };
    case 'Reservation.EmailUpdated':
      return {
        ...state,
        email: action.payload.email,
      };
    case 'Reservation.PhoneNumberUpdated':
      return {
        ...state,
        phoneNumber: action.payload.phoneNumber,
      };
    case 'Reservation.CompanyUpdated':
      return {
        ...state,
        company: action.payload.company,
      };
    case 'Reservation.StatusUpdated':
      return {
        ...state,
        status: action.payload.status,
      };
    case 'Reservation.WaiterUpdated':
      return {
        ...state,
        waiter: action.payload.waiter,
      };
    case 'Reservation.NewCommentUpdated':
      return {
        ...state,
        newComment: action.payload.newComment,
      };
    case 'Reservation.SpecialOfferUpdated':
      return {
        ...state,
        specialOffer: {
          nid: action.payload.specialOfferNid,
        },
      };
    case 'Reservation.SpecialOffersLoaded':
      return {
        ...state,
        specialOffers: action.payload.specialOffers,
      };
    case 'Reservation.AverageRatingLoaded':
      return {
        ...state,
        averageRating: action.payload.averageRating,
      };
    case 'Reservation.GuestDetailsLoaded':
      return {
        ...state,
        guestDetails: action.payload.guestDetails,
      };
    case 'Reservation.PaymentsLoaded':
      return {
        ...state,
        payments: action.payload.payments,
      };
    case 'Reservation.TagsUpdated':
      return {
        ...state,
        tags: action.payload.tags,
      };
    case 'Reservation.ClientDetailsUpdated':
      return {
        ...state,
        clientDetails: action.payload.clientDetails,
      };
    case 'Reservation.ClientTagsUpdated':
      return {
        ...state,
        clientTags: action.payload.clientTags,
      };
    case 'Reservation.NotificationsUpdated':
      return {
        ...state,
        notifications: { ...state.notifications, ...action.payload.notifications },
      };
    case 'Reservation.CommentDeleted':
      return {
        ...state,
        comments: state.comments.filter((el) => el.id !== action.payload.commentId),
      };
    case 'Reservation.CommentEdited':
      return {
        ...state,
        comments: state.comments.map((item) => {
          if (item.id === action.payload.commentId) {
            return {
              ...item,
              commentBody: action.payload.commentBody,
            };
          }
          return item;
        }),
      };
    case 'Reservation.CommentAdded':
      return {
        ...state,
        comments: [...state.comments, action.payload.comment],
      };
    case 'Reservation.ClientVipSet':
      return {
        ...state,
        clientIsVip: action.payload.isVip,
      };
    case 'Reservation.ClientBigSpenderSet':
      return {
        ...state,
        clientIsBigSpender: action.payload.isBigSpender,
      };
    case 'Reservation.SearchedUpdated':
      return {
        ...state,
        searched: action.payload.searched,
      };
    case 'Reservation.ReservationValidationErrorAdded':
      return {
        ...state,
        reservationSavingErrors: action.payload.errors,
      };
    case 'Reservation.ReservationValidationErrorRemoved':
      return {
        ...state,
        reservationSavingErrors: [],
      };
    case 'Reservation.LateCancellationNoShowDataLoaded':
      return {
        ...state,
        lateCancellationNoShow: action.payload.lateCancellationNoShow,
      };
    default:
      return state;
  }
}
