import moment from 'moment';
import { Dispatch } from 'redux';
import { getLanguagesCall, getRestaurantsListCall } from 'api/application';
import {
  AppConnectionStatus,
  LanguagesLoadedAction,
  PrintingUpdateAction,
  RestaurantsListLoadedAction,
  UpdateConnectionStatus,
  UpdateTimeAction,
} from 'types/application';

const applicationActions = {
  getLanguages() {
    return (dispatch: Dispatch) => {
      getLanguagesCall().then((languages) => {
        dispatch({
          type: 'Application.LanguagesLoaded',
          payload: { languages },
        } as LanguagesLoadedAction);
      });
    };
  },
  getRestaurantsList() {
    return (dispatch: Dispatch) => {
      getRestaurantsListCall().then((restaurantsList) => {
        dispatch({
          type: 'Application.RestaurantsListLoaded',
          payload: { restaurantsList },
        } as RestaurantsListLoadedAction);
      });
    };
  },
  updatePrinting(printing: boolean) {
    return {
      type: 'Application.PrintingUpdate',
      payload: { printing },
    } as PrintingUpdateAction;
  },
  updateTime(time: moment.Moment) {
    return {
      type: 'Application.SetCurrentTime',
      payload: { time },
    } as UpdateTimeAction;
  },
  updateConnectionStatus(connectionStatus: AppConnectionStatus) {
    return {
      type: 'Application.SetConnectionStatus',
      payload: { connectionStatus },
    } as UpdateConnectionStatus;
  },
};

export default applicationActions;
