import { AppDispatch } from 'redux/store';
import {
  createCustomBookingLengthCall,
  deleteCustomBookingLengthByIdCall,
  getCustomBookingLengthByIdCall,
  getCustomBookingLengthCall,
  getDefaultBookingLengthCall,
  updateCustomBookingLengthByIdCall,
  updateCustomBookingLengthOrderCall,
  updateDefaultBookingLengthCall,
} from 'api/app/restaurant/timeManagement';
import { CustomBookingLength, TimeManagementActionTypes } from 'types/app/timeManagement';
import { UpdateBookingLengthData } from 'api/app/restaurant/timeManagement';

const timeManagementActions = {
  getDefaultBookingLength() {
    return async (dispatch: AppDispatch) => {
      const response = await getDefaultBookingLengthCall();
      dispatch({
        type: TimeManagementActionTypes.UpdateDefaultBookingLength,
        payload: response,
      });

      return response;
    };
  },
  updateCustomBookingLengthOrder(data: number[]) {
    return async (dispatch: AppDispatch) => {
      await updateCustomBookingLengthOrderCall(data);
      dispatch({
        type: TimeManagementActionTypes.UpdateCustomBookingLengthOrder,
        payload: data,
      });
      return data;
    };
  },
  updateDefaultBookingLength(data: UpdateBookingLengthData) {
    return async (dispatch: AppDispatch) => {
      await updateDefaultBookingLengthCall(data);
      dispatch({
        type: TimeManagementActionTypes.UpdateDefaultBookingLength,
        payload: data,
      });

      return data;
    };
  },
  getCustomBookingLength() {
    return async (dispatch: AppDispatch) => {
      const response = await getCustomBookingLengthCall();
      dispatch({
        type: TimeManagementActionTypes.UpdateCustomBookingLength,
        payload: response,
      });

      return response;
    };
  },
  createCustomBookingLength(data: CustomBookingLength) {
    return async (dispatch: AppDispatch) => {
      const { id } = await createCustomBookingLengthCall(data);
      const payload = {
        id,
        ...data,
      };
      dispatch({
        type: TimeManagementActionTypes.CreateCustomBookingLength,
        payload,
      });

      return payload;
    };
  },
  updateCustomBookingLengthById(data: CustomBookingLength, id?: number) {
    return async (dispatch: AppDispatch) => {
      if (!id) return;

      await updateCustomBookingLengthByIdCall(data, id);
      dispatch({
        type: TimeManagementActionTypes.UpdateCustomBookingByIdLength,
        payload: {
          id,
          data,
        },
      });

      return data;
    };
  },
  deleteCustomBookingLengthById(id?: number) {
    return async (dispatch: AppDispatch) => {
      if (!id) return;

      await deleteCustomBookingLengthByIdCall(id);
      dispatch({
        type: TimeManagementActionTypes.DeleteCustomBookingByIdLength,
        payload: id,
      });

      return id;
    };
  },
  getCustomBookingLengthById(id?: number) {
    return async (dispatch: AppDispatch) => {
      if (!id) return;

      const response = await getCustomBookingLengthByIdCall(id);
      dispatch({
        type: TimeManagementActionTypes.GetCustomBookingByIdLength,
        payload: response,
      });

      return response;
    };
  },
};

export default timeManagementActions;
