import { Dispatch } from 'redux';
import { getSystemStatus } from 'api/system';
import { SystemStatusLoadedAction } from 'types/system';

const systemActions = {
  getStatus() {
    return (dispatch: Dispatch) => {
      getSystemStatus().then((status) => {
        dispatch({
          type: 'System.StatusLoaded',
          payload: { status },
        } as SystemStatusLoadedAction);
      });
    };
  },
};

export default systemActions;
