import { initialState } from './index';
import { AuthAction, AuthState } from 'types/auth';

export function authReducer(state: AuthState = initialState.auth, action: AuthAction): AuthState {
  switch (action.type) {
    case 'Auth.UserSettingsLoaded':
      return {
        userSettings: action.payload.userSettings,
      };
    default:
      return state;
  }
}
