import ThemeState, { ThemeModeAction } from 'types/themeState';
import { initialState } from './index';

export function themeReducer(
  state: ThemeState = initialState.theme,
  action: ThemeModeAction,
): ThemeState {
  switch (action.type) {
    case 'Theme.ModeLoaded':
      return {
        ...state,
        mode: action.payload.mode,
      };
    default:
      return state;
  }
}
